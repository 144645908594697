import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map, mergeMap } from "rxjs/operators";
import { TeamInfo } from "../shared/services/teams.service";
import { Observable } from "rxjs";
import { Team } from "../shared/models/team.model";
import { Training } from "../shared/models/training.model";
import { Game } from "../shared/models/game.model";

@Component({
  selector: "app-team-site",
  templateUrl: "./team-site.component.html",
  styleUrls: ["./team-site.component.scss"],
})
export class TeamSiteComponent implements OnInit {
  constructor(private teamInfo: TeamInfo, private route: ActivatedRoute) {
    this.mapWeekdayToScore = this.mapWeekdayToScore.bind(this);
    this.sortByWeekday = this.sortByWeekday.bind(this);
  }
  team: Observable<Team>;
  desktop: Boolean;
  displayedColumnsLarge: string[] = [
    "rank",
    "teamName",
    "totalGames",
    "totalPoints",
    "totalWins",
    "totalDraws",
    "totalLoss",
    "scoresPlus",
    "scoresMinus",
    "scoresDiff",
  ];

  displayedColumnsSmall: string[] = [
    "rank",
    "teamName",
    "totalGames",
    "totalPoints",
    "totalWins",
    "totalDraws",
    "totalLoss",
  ];

  displayedColumnsTraining: string[] = ["location", "time"];
  ngOnInit() {
    this.desktop = window.innerWidth >= 540;

    this.team = this.route.params.pipe(
      map((params) => params.id),
      mergeMap((id) =>
        this.teamInfo.watch({ id }).valueChanges.pipe(
          map((result) => {
            result.data.team.trainings.sort(this.sortByWeekday);
            return result.data.team;
          })
        )
      )
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
  }

  isNachwuchs(team: Team) {
    if (team === null) return false;
    return !(team.name.includes("Herren") || team.name.includes("Frauen"));
  }

  sortByWeekday(a: Training, b: Training) {
    return this.mapWeekdayToScore(a) - this.mapWeekdayToScore(b);
  }

  mapWeekdayToScore(training: Training) {
    if (training.time.includes("Montag")) return 1;
    else if (training.time.includes("Dienstag")) return 2;
    else if (training.time.includes("Mittwoch")) return 3;
    else if (training.time.includes("Donnerstag")) return 4;
    else if (training.time.includes("Freitag")) return 5;
    else if (training.time.includes("Samstag")) return 6;
    else if (training.time.includes("Sonntag")) return 7;
    else return 8;
  }

  isPlayed(game: Game): boolean {
    return game.gameStatus === "Gespielt";
  }

  isNotPlayed(game: Game): boolean {
    return game.gameStatus !== "Gespielt";
  }

  isActual(game: Game): boolean {
    return new Date(game.gameDateTime) > new Date();
  }
}
