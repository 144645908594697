import { Component, OnInit } from "@angular/core";
import { AllTeams } from "../shared/services/teams.service";
import { Team } from "../shared/models/team.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HostListener } from "@angular/core";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  teams: Observable<Team[]>;
  isMenuCollapsed = true;
  desktop = false;
  unifiedId : Observable<number>;

  constructor(private allTeamsGQL: AllTeams) {}

  ngOnInit(): void {
    this.teams = this.allTeamsGQL
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.teams.sort(this.sortTeams))
      );
    this.unifiedId = this.allTeamsGQL
      .watch()
      .valueChanges.pipe(
        map((result) => {
          const unifiedTeam = result.data.teams.find(team => team.name === "Unified");
          return unifiedTeam ? Number(unifiedTeam.id) : null;
        })
      );
  }

  sortTeams(a: Team, b: Team) {
    const teamANr = parseInt(a.name.replace(/[^0-9]/g, ""), 10);
    const teamBNr = parseInt(b.name.replace(/[^0-9]/g, ""), 10);
    if (isNaN(teamANr)) {
      return -1;
    } else if (isNaN(teamBNr)) {
      return 1;
    }
    return teamANr - teamBNr;
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
  }
}
