<div *ngIf="!desktop">
  <h1 *ngIf="team | async" class="title-table">
    {{ (team | async)?.name }}
  </h1>
  <img
    *ngIf="team | async"
    src="https://cms.hcrhintal.com{{ (team | async)?.teamPicture.url }}"
    alt=""
    class="img-fluid img-team"
  />
  <div *ngIf="(team | async)?.teamPictureCaption">
    <p
      *ngFor="let line of (team | async)?.teamPictureCaption.lines"
      class="team-picture-caption mat-small"
    >
      {{ line.title }}: {{ line.text }}
    </p>
  </div>
  <mat-divider></mat-divider>
  <h4 *ngIf="(team | async)?.games.length" class="title-table">Ergebnisse</h4>
  <app-games
    *ngIf="(team | async)?.games.length"
    [games]="(team | async)?.games"
  ></app-games>
  <h4 *ngIf="(team | async)?.ranking" class="title-table">Tabelle</h4>
  <mat-table
    *ngIf="(team | async)?.ranking"
    [dataSource]="(team | async)?.ranking"
    class="table"
  >
    <ng-container matColumnDef="rank">
      <mat-header-cell *matHeaderCellDef>POS</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.rank }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="teamName">
      <mat-header-cell class="team-cell" *matHeaderCellDef
        >TEAM</mat-header-cell
      >
      <mat-cell
        *matCellDef="let element"
        class="team-cell"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.teamName }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalGames">
      <mat-header-cell *matHeaderCellDef>SP.</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalGames }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalPoints">
      <mat-header-cell *matHeaderCellDef>PKT</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalPoints }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalWins">
      <mat-header-cell *matHeaderCellDef>S</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalWins }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalDraws">
      <mat-header-cell *matHeaderCellDef>U</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalDraws }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalLoss">
      <mat-header-cell *matHeaderCellDef>N</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalLoss }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="scoresPlus">
      <mat-header-cell *matHeaderCellDef>T+</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalScoresPlus }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="scoresMinus">
      <mat-header-cell *matHeaderCellDef>T-</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalScoresMinus }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="scoresDiff">
      <mat-header-cell *matHeaderCellDef>T+/-</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalScoresDiff }}</mat-cell
      >
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsSmall"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsSmall"></mat-row>
  </mat-table>
  <h4 *ngIf="(team | async)?.trainings.length > 0" class="title-table">
    Training
  </h4>
  <mat-table
    *ngIf="(team | async)?.trainings.length > 0"
    [dataSource]="(team | async)?.trainings"
    class="table"
  >
    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef>Zeit</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.time }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef>Ort</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.location }}</mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumnsTraining"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsTraining"></mat-row>
  </mat-table>
  <div class="btn-container">
    <a
      *ngIf="isNachwuchs(team | async)"
      class="mat-flat-button btn-form"
      href="mailto:nachwuchs@hcrheintal.ch"
      target="_blank"
      color="accent"
    >
      Für Probetraining anmelden
    </a>
  </div>
</div>
<div *ngIf="desktop" class="container">
  <h1 *ngIf="team | async" class="title-table name">
    {{ (team | async)?.name }}
  </h1>
  <img
    *ngIf="team | async"
    src="https://cms.hcrhintal.com{{ (team | async)?.teamPicture.url }}"
    alt=""
    class="img-fluid img-team"
  />
  <div *ngIf="(team | async)?.teamPictureCaption">
    <p
      *ngFor="let line of (team | async)?.teamPictureCaption.lines"
      class="team-picture-caption mat-small"
    >
      {{ line.title }}: {{ line.text }}
    </p>
  </div>
  <mat-divider></mat-divider>
  <h4 *ngIf="(team | async)?.games.filter(isPlayed).length" class="title-table">
    Ergebnisse
  </h4>
  <app-games-desktop
    *ngIf="(team | async)?.games.length"
    [games]="(team | async)?.games"
  ></app-games-desktop>
  <h4 *ngIf="(team | async)?.ranking" class="title-table">Tabelle</h4>
  <mat-table
    *ngIf="(team | async)?.ranking"
    [dataSource]="(team | async)?.ranking"
    class="table"
  >
    <ng-container matColumnDef="rank">
      <mat-header-cell *matHeaderCellDef>POS</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.rank }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="teamName">
      <mat-header-cell class="team-cell" *matHeaderCellDef
        >TEAM</mat-header-cell
      >
      <mat-cell
        *matCellDef="let element"
        class="team-cell"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.teamName }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalGames">
      <mat-header-cell *matHeaderCellDef>SP.</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalGames }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalPoints">
      <mat-header-cell *matHeaderCellDef>PKT</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalPoints }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalWins">
      <mat-header-cell *matHeaderCellDef>S</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalWins }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalDraws">
      <mat-header-cell *matHeaderCellDef>U</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalDraws }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="totalLoss">
      <mat-header-cell *matHeaderCellDef>N</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalLoss }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="scoresPlus">
      <mat-header-cell *matHeaderCellDef>T+</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalScoresPlus }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="scoresMinus">
      <mat-header-cell *matHeaderCellDef>T-</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalScoresMinus }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="scoresDiff">
      <mat-header-cell *matHeaderCellDef>T+/-</mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ bold: element.teamName.includes('HC Rheintal') }"
        >{{ element.totalScoresDiff }}</mat-cell
      >
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsSmall"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsSmall"></mat-row>
  </mat-table>
  <h4 *ngIf="(team | async)?.trainings.length > 0" class="title-table">
    Training
  </h4>
  <mat-table
    *ngIf="(team | async)?.trainings.length > 0"
    [dataSource]="(team | async)?.trainings"
    class="table last-table"
  >
    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef>Zeit</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.time }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef>Ort</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.location }}</mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumnsTraining"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsTraining"></mat-row>
  </mat-table>
  <div class="btn-container">
    <a
      *ngIf="isNachwuchs(team | async)"
      class="mat-flat-button btn-form"
      href="mailto:nachwuchs@hcrheintal.ch"
      target="_blank"
    >
      Für Probetraining anmelden
    </a>
  </div>
</div>
