import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Team } from '../shared/models/team.model';
import { AllTeamsTrainings } from '../shared/services/teams.service';

@Component({
  selector: 'app-training-site',
  templateUrl: './training-site.component.html',
  styleUrls: ['./training-site.component.scss']
})
export class TrainingSiteComponent implements OnInit {

  cols: number;
  desktop = true;
  displayedColumnsTraining: string[] = ["location", "time"];
  teams: Observable<Team[]>;

  constructor(
    private allTeamsTrainings: AllTeamsTrainings,
) { }

  ngOnInit(): void {
    this.desktop = window.innerWidth >= 540;
    this.cols = this.desktop ? 4 : 1;
    this.teams = this.allTeamsTrainings
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.teams.filter(this.isNachwuchs).sort(this.sortTeams)));
  }

  sortTeams(a: Team, b: Team) {
    const teamANr = parseInt(a.name.replace(/[^0-9]/g, ""), 10);
    const teamBNr = parseInt(b.name.replace(/[^0-9]/g, ""), 10);
    if (isNaN(teamANr)) {
      return -1;
    } else if (isNaN(teamBNr)) {
      return 1;
    }
    return teamANr - teamBNr;
  }

  isNachwuchs(team: Team) {
    if (team === null) return false;
    return !(team.name.includes("Herren") || team.name.includes("Frauen"));
  }
}
